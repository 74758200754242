/* eslint-disable max-len */
import React, { useState } from 'react';
import * as Styles from './BaixarRelatorioModal.styles';
import BaixarRelatorioImg from '../../assets/imgs/baixarRelatorio.png';
import Api from '../../helpers/api';

const BaixarRelatorioModal = ({ onClose, onDownloadComplete }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDownloadCSV = async () => {
    setIsProcessing(true);
    try {
      const response = await Api.get('/web/v2/active-principles-download-csv/', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('RESPONSE>', response);

      // Cria um objeto URL para o arquivo
      const url = window.URL.createObjectURL(new Blob([response]));

      // Cria um link temporário e inicia o download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();

      // Limpa o objeto URL após o download
      window.URL.revokeObjectURL(url);

      // Chama a função após o download e fecha o modal
      onDownloadComplete();
      onClose();
    } catch (error) {
      console.error('Error downloading CSV:', error);
      alert('Error downloading CSV. Please try again later.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Styles.ModalContainer>
      <Styles.ModalContent>
        <Styles.Image src={BaixarRelatorioImg} alt="Imagem 1" />

        <Styles.TextContent>
          <Styles.TextContentFirst>Baixar relatório de fármacos</Styles.TextContentFirst>
          <Styles.TextContentSecond>O relatório estará disponível para download após ser processado</Styles.TextContentSecond>
        </Styles.TextContent>

        <div>
          <Styles.Button onClick={handleDownloadCSV} disabled={isProcessing}>
            {isProcessing ? 'PROCESSANDO...' : 'BAIXAR RELATÓRIO'}
          </Styles.Button>
          <Styles.ButtonCancelar onClick={onClose}>CANCELAR</Styles.ButtonCancelar>
        </div>
      </Styles.ModalContent>
    </Styles.ModalContainer>
  );
};

export default BaixarRelatorioModal;
