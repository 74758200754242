/* eslint-disable max-len */
import React, { useState } from 'react';
import * as Styles from './AfterBaixarRelatorioModal.styles';
import SignatureBig from '../../assets/imgs/Signature_big_gif.gif';
import Api from '../../helpers/api';

const AfterBaixarRelatorioModal = ({ onClose, setLoading }) => {
  const [showAlternateContent, setShowAlternateContent] = useState(false);
  const [pdfAvailable, setPdfAvailable] = useState(false);

  const handleStartPdfGeneration = async () => {
    setLoading(true);
    try {
      await Api.post('/web/v1/active-principles-report/');
      onClose();
    } catch (error) {
      console.error('Erro ao iniciar a geração do PDF:', error);
      alert('Erro ao iniciar a geração do PDF. Por favor, tente novamente mais tarde.');
      setLoading(false);
    }
  };

  return (
    <Styles.ModalContainer>
      <Styles.ModalContent>
        <Styles.Image src={SignatureBig} alt="Imagem 3" />
        <Styles.TextContent>
          <Styles.TextContentFirst>O arquivo está sendo processado</Styles.TextContentFirst>
          <Styles.TextContentSecond>O relatório está sendo processado e será disponibilizado para download em breve.</Styles.TextContentSecond>
        </Styles.TextContent>
        <div>
          <Styles.Button onClick={handleStartPdfGeneration}>
            OK, ENTENDI
          </Styles.Button>
        </div>
      </Styles.ModalContent>
    </Styles.ModalContainer>
  );
};

export default AfterBaixarRelatorioModal;
