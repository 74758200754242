import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 496px;
  height: 460px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Image = styled.img`
width: 204.465px;
height: 204.465px;
margin-bottom: 10px;
`;

export const Button = styled.button`
padding: 10px 20px;
margin-right: 10px;
border: none;
border-radius: 10px;
background: var(--main-ax-reg-light-d-1-f-2-f-1, #D1F2F1);
color: white;
cursor: pointer;

color: var(--main-ax-reg-default-1-bbfbb, #1BBFBB);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

// font-family: "SF Pro Text";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.084px;
text-transform: uppercase;
margin-top: 20px;
`;

export const TextContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const TextContentFirst = styled.p`
color: var(--Deep-blue, #343F5C);
text-align: center;
// font-family: "SF Pro Text";
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.086px;
`;

export const TextContentSecond = styled.p`
color: var(--deep-blue-default-343-f-5-c, #343F5C);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* Paragraph */
// font-family: "SF Pro Text";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 22px; /* 137.5% */
letter-spacing: -0.41px;

width: 334px;
`;
