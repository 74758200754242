/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import styled from 'styled-components';
import { AiOutlineLoading } from 'react-icons/ai';
import { generatePath } from 'react-router-dom';

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Spin,
  Button,
  Card,
  Input,
  Tag,
  Modal,
  Form,
  notification,
  Divider,
  Tree,
  Alert,
  Avatar,
} from 'antd';

import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import InfoImg from '../../assets/imgs/Info.png';
import AfterBaixarRelatorioModal from '../AfterBaixarRelatorioModal/AfterBaixarRelatorioModal';
import BaixarRelatorioModal from '../BaixarRelatorioModal/BaixarRelatorioModal';
import EditingPlace from '../EditingPlace';
import { pathRoutes } from '../../routes';
import { drugsActions } from '../../actions';
import { drugsService } from '../../services';
import { stringsHelp } from '../../helpers';
import SelectInstitutionsGroup from '../SelectInstitutionsGroup';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const { Search } = Input;
const { TreeNode } = Tree;

const ButtonBaixarRelatorios = styled.button`
  width: 135px;
  height: 25px;
  flex-shrink: 0;
  border: none;
  border-radius: 20px;
  background: #1BBFBB;
  margin-bottom: 10px;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;

  text-transform: uppercase;
  // opacity: ${({ showTopBar }) => (showTopBar ? 0.5 : 1)};
  opacity: ${({ showDownloadButtonLoading }) => (showDownloadButtonLoading ? 0.5 : 1)}; // Atualização aqui
  pointer-events: ${({ showTopBar }) => (showTopBar ? 'none' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px; /* Espaçamento entre o texto e o ícone */
`;

const LoadingIcon = styled(AiOutlineLoading)`
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ContentButtonAndImg = styled.div`
display: flex;
flex-direction: row;
`;

const InfoText = styled.span`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
  top: -50px; /* Ajuste a posição conforme necessário */
  left: 200px;
  width: 400px;
  transform: translateX(-50%);
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

class Drugs extends Component {
  state = {
    modalVisible: false,
    actionNew: true,
    loadingSave: false,
    modalDrug: undefined,
    modalLoading: false,
    defaultCheckedKeys: [],
    checkedDrug: { forms: [] },
    dataDrugs: [],
    selectGroupInstitutionId: null,
    count: null,
    pagination: false,
    sourceClass: [],
    modalBaixarRelatorioVisible: false,
    modalAfterBaixarRelatorioVisible: false,
    showTopBar: false,
    showDownloadButtonLoading: false,
    showInfoText: false,
    modalEmailIsOpen: false,
  };

  cancelSource = CancelToken.source();

  onCheck = (checkedKeys, info) => {
    const arrayForms = { forms: [] };

    this.state.modalDrug.forms.map((currentF) => {
      const arayBrands = [];

      info.checkedNodes.map((currentB) => {
        if (currentB.key.includes('b-')) {
          if (currentF.id === currentB.ref) {
            arayBrands.push(parseInt(currentB.key.replace('b-', '')));
          }
        }
        return currentB;
      });

      if (arayBrands.length) {
        arrayForms.forms.push({
          id: currentF.id,
          active_principle_brands: arayBrands,
        });
      } else {
        arrayForms.forms.push({ id: currentF.id, active_principle_brands: [] });
      }

      return currentF;
    });

    this.setState({ checkedDrug: arrayForms });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false, loadingSave: false });
  };

  openModal = (p_id) => {
    let promiseRead;
    this.setState({ modalVisible: true, modalLoading: true });

    mixPanelService.tracking(mixPanelService.FARMACO_CONFIGURAR);

    let objFilter = {};
    if (this.state.selectGroupInstitutionId) {
      objFilter = { params: { institution_id: this.state.selectGroupInstitutionId } };
    }

    promiseRead = drugsService.getDrug(p_id, objFilter).then((result) => {
      let newDrug;
      const defaultCheck = [];
      const checkedDrug = { forms: [] };

      if (result) {
        newDrug = {};

        newDrug.class = {
          value: result.active_principle_class.id,
          text: result.active_principle_class.active_principle_class_i18n[0].name,
        };
        newDrug.id = result.id;
        newDrug.name = result.active_principle_i18n[0].name;
        newDrug.conversion_factor_to_mg = result.conversion_factor_to_mg;
        newDrug.forms = [];

        result.forms.map((form) => {
          const newForm = {
            id: form.id,
            conversion_factor_to_mg: result.conversion_factor_to_mg,
            name:
              (form.presentation ? `${form.presentation} x ` : '')
              + (form.type ? `${form.type} ` : ' ')
              + (form.presentation_volume
                ? `${form.presentation_volume}${form.presentation_volume_unit}`
                : ''),
            brands: [],
          };
          const arayBrands = [];

          form.brands.map((brand) => {
            newForm.brands.push({
              id: brand.id,
              name: stringsHelp.firstLetterUpper(brand.brand),
              laboratory: stringsHelp.firstLetterUpper(brand.laboratory),
            });
            if (brand.institutions.length) {
              arayBrands.push(brand.id);
              defaultCheck.push(`b-${brand.id}`);
            }
            return brand;
          });

          checkedDrug.forms.push({
            id: form.id,
            active_principle_brands: arayBrands,
          });

          newDrug.forms.push(newForm);

          return form;
        });
      }

      this.setState({
        modalLoading: false,
        modalDrug: newDrug,
        defaultCheckedKeys: defaultCheck,
        checkedDrug,
      });
    });

    promiseRead.catch((error) => {
      console.log(error);
      notification.error({
        message:
          'Falha ao carregar detalhes de agendamento, notifique o suporte técnico!',
      });
    });

    drugsService.getDrugClasses().then((result) => {
      let options = [];
      options = result.map((x) => ({ value: x.id, text: x.active_principle_class_i18n[0].name }));

      this.setState({ sourceClass: options });
    });
  };

  load = () => {
    const { dispatch, drugs } = this.props;
    if (drugs.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    let institution = {};
    if (this.state.selectGroupInstitutionId) {
      institution = { params: { institution_id: this.state.selectGroupInstitutionId } };
    }

    dispatch(drugsActions.getDrugs(this.cancelSource.token, institution, undefined, 1, 10)).then(
      (data) => {
        this.setState({
          dataDrugs: data.activePrinciples,
          count: data.count,
          pagination: {
            total: data.count,
            defaultPageSize: 10,
            onChange: (page) => this.changePage(page, undefined),
          },
        });
      },
    );
  };

  filterInstitutionGroup = (value) => {
    if (!value) return;

    this.setState({ selectGroupInstitutionId: value }, () => {
      this.load();
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.checkedDrug.forms) {
      let promiseSave;

      this.setState({ loadingSave: true });

      mixPanelService.tracking(mixPanelService.FARMACO_SALVAR);

      if (this.state.selectGroupInstitutionId) {
        this.state.checkedDrug.institution_id = this.state.selectGroupInstitutionId;
      }

      promiseSave = drugsService
        .putDrug(this.state.modalDrug.id, this.state.checkedDrug)
        .then((result) => {
          this.handleCancel();

          notification.success({
            message: 'Configuração realizada com sucesso!',
          });

          this.load();
        });

      promiseSave.catch((error) => {
        console.log(error);
        this.setState({ loadingSave: false });
        notification.error({
          message: 'Falha ao realizar configuração, tente novamente mais tarde!',
        });
      });
    } else {
      notification.error({
        message:
          'Selecione a marca comercial de acordo com apresentação farmacológica!',
      });
    }
  };

  handleIndividualSubmit = (params, close) => {
    this.setState({ modalDrug: { ...this.state.modalDrug, class: params } });
    drugsService.putDrugFields(params.pk, { [params.name]: params.value }).then((result) => {
      close();
      this.load();
      notification.success({
        message:
          'Classe atualizada com sucesso!',
      });
    }).catch((error) => {
      close();
      console.log(error);
      notification.error({
        message:
          'Não foi possível atualizar a classe do fármaco!',
      });
    });
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_FARMACO);

    if (
      JSON.parse(localStorage.getItem('selected-institution'))
      && JSON.parse(localStorage.getItem('selected-institution')).is_group
    ) { return; }

    this.load();

    mixPanelService.tracking(mixPanelService.MENU_FARMACO);
  }

  changePage = (page, filter) => {
    this.setState({ loading: true });
    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();
    let location;
    if (filter) {
      location = generatePath(pathRoutes.drugs, { page, filter });
    } else {
      location = generatePath(pathRoutes.drugs, { page });
    }
    this.props.history.push(location);

    const { dispatch } = this.props;

    let institution = {};
    if (this.state.selectGroupInstitutionId) {
      institution = { params: { institution_id: this.state.selectGroupInstitutionId } };
    }
    this.page = page;
    this.filterValue = filter;

    dispatch(
      drugsActions.getDrugs(this.cancelSource.token, institution, filter, page, 10),
    ).then((data) => {
      this.setState({
        loading: false,
        loadingSearch: true,
        dataDrugs: data.activePrinciples,
        count: data.count,
        pagination: {
          total: data.count,
          defaultPageSize: 10,
          current: page,
        },
      });
    });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  formatDrugs = (drugs) => {
    const newDrugs = [];

    if (drugs) {
      drugs.forEach((drug) => {
        const newObj = drug;
        newObj.name = drug.active_principle_i18n[0].name;
        newDrugs.push(newObj);
      });
    }

    return newDrugs;
  };

  searchTable = (e) => {
    const { drugs, dispatch } = this.props;
    const filter = e.target.value;

    if (drugs.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    let institution = {};
    if (this.state.selectGroupInstitutionId) {
      institution = { params: { institution_id: this.state.selectGroupInstitutionId } };
    }
    setTimeout(() => {
      dispatch(drugsActions.getDrugs(this.cancelSource.token, institution, filter, 1, 10)).then(
        (data) => {
          this.setState({
            dataDrugs: data.activePrinciples,
            count: data.count,
            pagination: {
              total: data.count,
              defaultPageSize: 10,
              current: 1,
              onChange: (page) => this.changePage(page, filter),
            },
          });
        },
      );
    }, 900);
  };

  handleOpenModal = () => {
    this.setState({ modalBaixarRelatorioVisible: true });
  };

  handleCloseModal = () => {
    this.setState({ modalBaixarRelatorioVisible: false });
  };

  handleOpenSecondModal = () => {
    this.setState({ modalBaixarRelatorioVisible: false, modalAfterBaixarRelatorioVisible: true });
  };

  handleCloseSecondModal = () => {
    this.setState({ modalAfterBaixarRelatorioVisible: false, showTopBar: true });
  };

  handleCloseTopBar = () => {
    this.setState({ showTopBar: false });
  };

  handleContentLoaded = () => {
    // Após o conteúdo ser carregado, definir showDownloadButtonLoading de volta para false
    this.setState({ showDownloadButtonLoading: false });
  }

  handleDownloadButtonLoading = (isLoading) => {
    this.setState({ showDownloadButtonLoading: isLoading });
  };

  handleDownloadComplete = () => {
    this.setState({ showTopBar: false, showDownloadButtonLoading: false });
  };

  render() {
    const { drugs, institutions } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));

    const columnsTableDrugs = [
      {
        title: 'Princípio ativo',
        dataIndex: 'lasa',
        key: 'lasa',
        render: (lasa, record) => (
          <div>
            <h4 style={{ color: '#343f5c' }}>
              <span dangerouslySetInnerHTML={{ __html: (record.active_principle_i18n.lasa || record.active_principle_i18n.name).replace(/<lasa>(.*?)<\/lasa>/g, (_, match) => `<strong>${match}</strong>`) }} />
            </h4>
            <Tooltip title="Apresentações comerciais">
              {' '}
              <div style={{ margin: 0 }}>
                <Icon type="tag" />
                {' '}
&nbsp;
                {record.brands[0].name.split(',').map((item, i) => (

                  <span style={{ color: 'rgba(111, 110, 110, 0.73)' }} key={i}>
                    {i > 0 ? <Divider type="vertical" /> : ''}
                    {item.institutions ? (
                      <Tag>{stringsHelp.firstLetterUpper(item)}</Tag>
                    ) : (
                      stringsHelp.firstLetterUpper(item)
                    )}
                  </span>
                ))}
              </div>
            </Tooltip>
          </div>
        ),
      },

      {
        title: 'Classe farmacológica',
        dataIndex: 'active_principle_class',
        key: 'active_principle_class',
        width: 200,
        render: (active_principle_class) => (
          <Tag>
            {active_principle_class.active_principle_class_i18n.name}
          </Tag>
        ),
      },
      {
        title: 'Configurar',
        dataIndex: 'id',
        key: 'id',
        width: 120,
        className: 'text-center',
        render: (id) => (
          <Tooltip placement="left" title="Configurar apresentações">
            <span>
              <Button type="link" onClick={() => this.openModal(id)}>
                <Icon type="setting" />
              </Button>
            </span>
          </Tooltip>
        ),
      },
    ];

    return (

      <Content>
        {JSON.parse(localStorage.getItem('selected-institution'))
        && JSON.parse(localStorage.getItem('selected-institution')).is_group && !this.state.selectGroupInstitutionId ? (
          <Row>
            <Col xs={24} sm={0}>
              <p>&nbsp;</p>
            </Col>
            <Col xs={24} lg={12} xxl={16}>
              <h1 className="title">Fármacos</h1>
            </Col>
            <Col xs={24} sm={0}>
              <div style={{ marginBottom: 10 }}>
                <SelectInstitutionsGroup onChange={this.filterInstitutionGroup} />
              </div>
            </Col>
            <Col xs={0} lg={12} xxl={8}>
              <div style={{ display: 'flex' }}>
                <SelectInstitutionsGroup onChange={this.filterInstitutionGroup} />
              </div>
            </Col>
            {this.state.selectGroupInstitutionId ? (
              <Col span={24} style={{ paddingBottom: 10 }}>
                <Search
                  placeholder="Buscar"
                  onChange={this.searchTable}
                  style={{ width: '100%' }}
                  disabled={drugs.loading}
                />
              </Col>
            ) : (
              <Col span={24} style={{ paddingBottom: 10 }}>
                <Alert
                  message="Selecione a instituição para listar as configurações internas de fármacos"
                  type="info"
                  closeText={
                    <Icon style={{ color: '#1890ff' }} type="arrow-up" />
                  }
                  showIcon
                />
              </Col>
            )}
          </Row>
          ) : (
            <Row>
              <Col xs={24} lg={0}>
                <p>&nbsp;</p>
              </Col>

              <div>
                <Col xs={24} lg={12}>
                  <h1 className="title">Fármacos</h1>
                  <ContentButtonAndImg>
                    <ButtonBaixarRelatorios
                      type="submit"
                      onClick={this.handleOpenModal}
                      showTopBar={this.state.showTopBar}
                      showDownloadButtonLoading={this.state.showDownloadButtonLoading}
                    >
                      {this.state.showTopBar && this.state.showDownloadButtonLoading ? (
                        <>
                          BAIXAR RELATÓRIO
                          {' '}
                          <LoadingIcon
                            style={{
                              color: 'white',
                              strokeWidth: '23px',
                              width: '13px',
                              height: '13px',
                              fontWeight: 'bold',
                            }}
                          />
                        </>
                      ) : 'BAIXAR RELATÓRIO'}

                    </ButtonBaixarRelatorios>
                    <div
                      style={{ position: 'relative' }}
                      onMouseEnter={() => this.setState({ showInfoText: true })}
                      onMouseLeave={() => this.setState({ showInfoText: false })}
                    >
                      <img src={InfoImg} alt="Info" />
                      <InfoText show={this.state.showInfoText}>O relatório apresenta informações de princípios ativos e apresentações configurados para a instituição.</InfoText>
                    </div>

                    {this.state.modalBaixarRelatorioVisible && (
                    <BaixarRelatorioModal onClose={this.handleCloseModal} onOpenSecondModal={this.handleOpenSecondModal} onDownloadComplete={this.handleDownloadComplete} />
                    )}
                    {this.state.modalAfterBaixarRelatorioVisible && (
                    <AfterBaixarRelatorioModal onClose={this.handleCloseSecondModal} setLoading={this.handleDownloadButtonLoading} onDownloadComplete={this.handleDownloadComplete} />
                    )}
                  </ContentButtonAndImg>

                </Col>
              </div>

              <Col xs={24} lg={0}>
                <div style={{ marginBottom: 10, position: 'fixed' }}>
                  <Search
                    placeholder="Buscar"
                    onChange={this.searchTable}
                    style={{ width: '100%', position: 'fixed' }}
                  />
                </div>
              </Col>
              <Col xs={0} lg={12}>
                <div style={{ display: 'flex' }}>
                  <Search
                    placeholder="Buscar"
                    onChange={this.searchTable}
                    style={{ width: '100%' }}
                    disabled={drugs.loading}
                  />
                </div>
              </Col>
            </Row>

          )}
        <Row>
          {JSON.parse(localStorage.getItem('selected-institution'))
          && JSON.parse(localStorage.getItem('selected-institution')).is_group
          && !this.state.selectGroupInstitutionId ? (
            <div />
            ) : (
              <Table
                size="midle"
                key="tableDrugs"
                loading={drugs.loading}
                dataSource={this.state.dataDrugs}
                columns={columnsTableDrugs}
                pagination={this.state.pagination}
                footer={() => {
                  const rowsDataSource = !this.state.dataDrugs
                    ? 0
                    : this.state.dataDrugs.length;
                  return (
                    <span>
                      Total de registros:
                      {' '}
                      <Tag>{this.state.count}</Tag>
                    </span>
                  );
                }}
              />
            )}
        </Row>
        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          title={(
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleCancel}
              >
                <i className="icon-close" />
              </button>
              <span>Configurar apresentações</span>
              <span style={{ float: 'right' }}>
                {' '}
                <Tooltip
                  placement="bottom"
                  title="Selecione as marcas comerciais de medicamentos de acordo com a apresentação farmacológica que a instituição utiliza!"
                >
                  {' '}
                  <Button shape="circle" icon="info" />
                  {' '}
                </Tooltip>
                {' '}
              </span>
            </div>
          )}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={this.handleSubmit}
              loading={this.state.loadingSave}
            >
              Salvar
            </Button>,
          ]}
          bodyStyle={{ paddingTop: '15px' }}
          width="701px"
          zIndex={1024}
        >
          {!this.state.modalLoading && this.state.modalDrug ? (
            <div>
              {this.state.selectGroupInstitutionId && (
              <div>
                {JSON.parse(localStorage.getItem('selected-institution'))
                  && institutions.items
                  && institutions.items.size > 1
                  ? institutions.items
                    .filter(
                      (i) => i.id === this.state.selectGroupInstitutionId,
                    )
                    .map((institution) => (
                      <div className="ant-row ant-form-item">
                        <div className="ant-col ant-form-item-label">
                          <label
                            className="ant-form-item-no-colon"
                            title="INSTITUIÇÃO"
                          >
                            INSTITUIÇÃO
                          </label>
                        </div>

                        <div
                          className="ant-list-item-meta ant-group"
                          style={{ marginTop: 10 }}
                        >
                          <div className="ant-list-item-meta-avatar">
                            <Avatar
                              shape="square"
                              src={
                                      institution.logo_url
                                        ? institution.logo_url
                                        : null
                                    }
                              className="institution-logo"
                            >
                              Logo
                            </Avatar>
                          </div>
                          <div className="ant-list-item-meta-content">
                            <h4
                              className="ant-list-item-meta-title"
                              style={{ marginBottom: -5 }}
                            >
                              {stringsHelp.formatName(institution.name)}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))
                  : ''}
              </div>
              )}

              <Card
                title={(
                  <span style={{ fontSize: '14px', color: '#343f5c' }}>
                    {this.state.modalDrug.name.toUpperCase()}
                    {' '}
                    {this.state.modalDrug.conversion_factor_to_mg != null
                      ? (
                        <small>
                          (fator de conversão ui/utr
                          {' '}
                          {this.state.modalDrug.conversion_factor_to_mg}
                          {' '}
                          para 1mg)
                        </small>
                      )
                      : ''}
                    {user.type === 'AD' ? (
                      <span style={{ fontWeight: 'normal' }}>
                        <br />
                        <EditingPlace
                          type="select"
                          placeHolder="Escolha a Classe"
                          selected={this.state.modalDrug.class}
                          source={this.state.sourceClass}
                          callback={this.handleIndividualSubmit}
                          pk={this.state.modalDrug.id}
                          name="class_id"
                        />
                      </span>
                    ) : null}
                  </span>
                )}
                bordered={false}
                extra={(
                  <small>
                    <Icon type="setting" />
                    {' '}
                    <b>Apresentação</b>
                    {' '}
                    <Divider type="vertical" />
                    {' '}
                    <Icon type="tag" />
                    {' '}
                    Marca
                    comercial
                    {' '}
                  </small>
                )}
              >
                <Alert
                  style={{ padding: '4px 15px', marginBottom: 5 }}
                  message={(
                    <span>
                      Lista de medicamentos padrões da
                      {' '}
                      <b>Anvisa.</b>
                      {' '}
                      <a
                        style={{ float: 'right' }}
                        href="http://portal.anvisa.gov.br/registros-e-autorizacoes/medicamentos/produtos/medicamentos-de-referencia/lista"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Saiba mais
                      </a>
                    </span>
                  )}
                  type="warning"
                />
                <Tree
                  showIcon
                  checkable
                  defaultCheckedKeys={this.state.defaultCheckedKeys}
                  onCheck={this.onCheck}
                  defaultExpandAll
                >
                  {this.state.modalDrug.forms.map((currentForm) => (
                    <TreeNode
                      icon={<Icon type="setting" />}
                      title={(
                        <span>
                          <b>{currentForm.name.replaceAll('ui', 'Unidades')}</b>
                        </span>
                        )}
                      key={`f-${currentForm.id}`}
                      selectable={false}
                    >
                      {currentForm.brands.map((currentBrand) => (
                        <TreeNode
                          icon={<Icon type="tag" />}
                          title={(
                            <span>
                              {currentBrand.name}
                              {' '}
                              {'  '}
                              <small
                                style={{
                                  color: 'rgba(111, 110, 110, 0.65)',
                                }}
                              >
                                {currentBrand.laboratory}
                              </small>
                            </span>
                              )}
                          ref={currentForm.id}
                          key={`b-${currentBrand.id}`}
                          selectable={false}
                        />
                      ))}
                    </TreeNode>
                  ))}
                </Tree>
              </Card>

            </div>
          ) : (
            <Row style={{ textAlign: 'center', padding: 20 }}>
              <Spin />
            </Row>
          )}
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { drugs, institutions } = state;
  return {
    drugs,
    institutions,
  };
}

const connected = connect(mapStateToProps)(Form.create()(Drugs));
export default connected;
