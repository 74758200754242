/* eslint-disable react/destructuring-assignment */
import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Layout,
  Row,
  Card,
  notification,
  Typography,
  Modal,
} from 'antd';
import { generatePreviewHtml, generatePreviewCss } from '../../assets/html/tcleDefaultTerm';

import { institutionService } from '../../services/institution.service';
import { pathRoutes } from '../../routes';

const { Content } = Layout;

class PreAnesthesiaSettingsTcle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      patientPreSettingsTcle: '',
      modalVisible: false,
      value: '',
      charCountLines: 0,
      maxLines: 1000, // Define o limite máximo de linhas
      disabled: false,
      quillRef: null,
      previewhtml: '',
    };
    this.formats = ['bold', 'italic', 'strike', 'undo', 'redo'];
    this.modules = {
      toolbar: {
        container: [
          ['bold', 'italic', 'strike'],
          // ['undo', 'redo'],
        ],
        /*    handlers: {
          undo: this.myUndo,
          redo: this.myRedo,
        },
        undo: {
          icon: img,
          handler: this.myUndo,
        },
        redo: {
          icon: img,
          handler: this.redo,
        }, */
      },
      history: {
        delay: 2000,
        maxStack: 6020,
        userOnly: true,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.quillRefCallback = this.quillRefCallback.bind(this); // Adicione este método
  }

  async componentDidMount() {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.handlePopstate);
    const valueDb = await institutionService.getSettingsPreTcle();
    const onlyText = valueDb.value.replace(/<[^>]+>/g, '');
    this.setState({ value: valueDb.value, charCount: onlyText.length });
    await this.validateFinalHtml(valueDb.value);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopstate);
  }

  async handleChange(e) {
    const text = e.replace(/<[^>]+>/g, '');
    if (e !=  this.state.value)
      this.setState({ value: e, charCount: text.length });
    await this.validateFinalHtml(e);
    
    const editor = this.reactQuillRef.getEditor();
    if (editor.getSelection()) {
      const cursorPosition = editor.getSelection().index;
      editor.setSelection(cursorPosition + 1);
    }
  }

  breakLineIfNecessary = (termDescription) => {
    let paragraphs = termDescription.split('<p>');
    paragraphs = paragraphs.map(paragraph => paragraph.replace('</p>', ''))
    const correctedParagraphs = [];
    
    paragraphs.filter(p => p != '').forEach(paragraph => {
      if (paragraph.replace(/<\/?[^>]+(>|$)/g, '').length > 130) {
        const numberOfNecessaryBrs = Math.ceil(paragraph.length / 130);

        for (let index = 0; index < numberOfNecessaryBrs; index++) {
          const currentParagraph = paragraph.slice(index * 130, index * 130 + 130);
          correctedParagraphs.push(`<p>${currentParagraph}</p>`);
        }
        
        return;
      }
      correctedParagraphs.push(`<p>${paragraph}</p>`);
    })

    return correctedParagraphs.length ? correctedParagraphs.join('') : termDescription;
  }

  handleSubmit = async (value) => {
    try {
      const validate = await this.validateFinalHtml(value);
      const onlyValue = value.replace(/<\/?[^>]+(>|$)/g, '');
      if (validate <= 1000) {
        if (onlyValue.length > 0) {
          await institutionService.settingsPreTcle(value).then(async () => {
            notification.success({
              message: 'Configurações do termo alteradas com sucesso',
            });
            setTimeout(() => {
              this.props.history.push({
                pathname: pathRoutes.pre_anesthesia,
              });
            }, 2000);
          });
        } else {
          notification.error({
            message: 'Não é possivel salvar um termo em branco',
          });
        }
      } else {
        notification.error({
          message: 'Erro quantidade de caracteres ou linhas excedida',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Erro ao atualizar configurações do pré-anestésico',
      });
      this.setState({ loading: false });
    }
  }

  handlePreviewClick = async () => {
    let previewValue = this.state.value;
    const generateCss = generatePreviewCss();
    const regex = /<p\b[^>]*>/gi;
    previewValue = previewValue.replace(regex, '<p style=\'margin:0 ;width:auto; float: left;\'>');
    const generatehtml = generatePreviewHtml(previewValue, generateCss);
    this.setState({ modalVisible: true, previewhtml: generatehtml });
  }

  handleCancel = () => {
    this.setState({ modalVisible: false });
  }

  handlePopstate = () => {
    Modal.confirm({
      title: 'Atenção',
      content: 'Ao retornar os dados não serão salvos, deseja continuar?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => {
        this.props.history.push({
          pathname: pathRoutes.pre_anesthesia,
        });
      },
    });
  };

  myUndo = () => {
    const myEditor = this.reactQuillRef.getEditor();
    return myEditor.history.undo();
  }

  myRedo = () => {
    const myEditor = this.reactQuillRef.getEditor();
    return myEditor.history.redo();
  }

  validateFinalHtml = async (html) => {
    const removeExtraP = html.replace(/<p><br><\/p>/g, '<br>'); // troca as tags br
    const brForP = removeExtraP.replace(/<\/p>/g, '<br>'); // troca os fins dos paragrafos
    const numBrHtml = (brForP.match(/<br>/g) || []).length; // busca por todos os br
    const text = html.replace(/<[^>]+>/g, ''); // remove todos as tags
    // Divide a string em palavras e soma seus caracteres
    let sum = 0;
    let result = '';
    text.split(' ').forEach((word) => {
      if (sum + word.length + 1 > 140) { // +1 para contar o espaço em branco entre as words
        result += '<br>';
        sum = 0;
      }
      sum += word.length + 1;
      result += `${word} `;
    });

    const numBr = (result.match(/<br>/g) || []).length;
    let countLines = (this.state.maxLines - (numBr + numBrHtml));
    countLines = countLines < 0 ? 0 : countLines;
    this.setState({ charCountLines: countLines });
    return numBr + numBrHtml;
  }

  redirectToPreAnesthesia = () => {
    this.props.history.push({
      pathname: pathRoutes.pre_anesthesia,
    });
    return null;
  }

  quillRefCallback(ref) {
    if (ref) {
      this.setState({ quillRef: ref });
    }
  }

  render() {
    const institution = JSON.parse(localStorage.getItem('selected-institution'));
    const user = JSON.parse(localStorage.getItem('user'));
    const userInstitutions = JSON.parse(localStorage.getItem('user-permissions')).institutions;
    const findUserInstitution = userInstitutions.find((i) => i.id === institution.id);
    const institutionAcepted = (institution.is_group || institution.group_id === null
      || findUserInstitution) ? (
        <Content>
          <Form>
            <Row>
              <Col xs={24} sm={20}>
                <h1 className="title">
                  Personalização do Termo de Consentimento Livre e Esclarecido (TCLE)
                </h1>
              </Col>
              <Col xs={0} sm={4}>
                <Button
                  type="primary"
                  className="add-procedure"
                  htmlType="submit"
                  loading={this.loading}
                  onClick={() => this.handleSubmit(this.state.value)}
                >
                  SALVAR
                </Button>
              </Col>
            </Row>
            <div style={{ margin: 20 }} />
            <Card
              title="Termo de Consentimento Livre e Esclarecido (TCLE)"
              headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }}
              size="default"
              bodyStyle={{ paddingTop: 5 }}
            >
              <Row gutter={28}>
                <Row style={{ marginTop: '20px' }}>
                  <Typography.Text
                    style={{
                      fontWeight: 400,
                      marginLeft: 11,
                    }}
                  >
                    Informe apenas os parágrafos do documento.
                    Os dados do paciente e anestesista serão impressos automaticamente no termo.
                  </Typography.Text>
                </Row>
              </Row>
            </Card>
            <Card
              headStyle={{ backgroundColor: '#f2f2f2' }}
              size="default"
              bodyStyle={{ paddingTop: 5, height: '590px' }}
            >
              <ReactQuill
                style={{ height: '500px' }}
                value={this.state.value}
                onChange={change => this.handleChange(this.breakLineIfNecessary(change))}
                modules={this.modules}
                formats={this.formats}
                disabled={this.state.disabled}
                ref={(el) => { this.reactQuillRef = el; }}
                pasteAsPlainText
              />
              <div style={{ textAlign: 'right', marginTop: '50px' }}>
                <Button
                  type="primary"
                  className="add-procedure"
                  loading={this.loading}
                  onClick={this.handlePreviewClick}
                >
                  PREVIEW
                </Button>
              </div>
            </Card>
            <Col xs={0} sm={24}>
              <p>
                {this.state.charCountLines}
                {' '}
                linhas restantes
              </p>
            </Col>
            <Col xs={0} sm={24}>

              <Modal
                title="Preview"
                visible={this.state.modalVisible}
                onCancel={this.handleCancel}
                width={1000}
                height={600}
                footer={[
                  <Button key="ok" type="primary" onClick={this.handleCancel}>
                    OK
                  </Button>,
                ]}
              >
                <div dangerouslySetInnerHTML={{ __html: this.state.previewhtml }} />

              </Modal>

            </Col>
          </Form>
        </Content>
      ) : (
        null
      );
    return institutionAcepted || this.redirectToPreAnesthesia();
  }
}

function mapStateToProps(state) { return state; }

const connected = withRouter(connect(mapStateToProps)(Form.create()(PreAnesthesiaSettingsTcle)));
export default connected;
